import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Firm from '../views/Firm.vue'
import Areas from '../views/Areas.vue'
import Team from '../views/Team.vue'
import News from '../views/News.vue'
import Contact from '../views/Contact.vue'
import TermsConditions from "../views/TermsConditions";
import PrivacyPolicy from "../views/PrivacyPolicy";
import NewsInfos from "../views/NewsInfos";
import NewsEvents from "../views/NewsEvents";

Vue.use(VueRouter)

function createPathRoute(path, name, component) {
    return {
        path: path,
        name: name,
        component: component
    }
}

function createRedirectRoute(pathWildcard, redirectPath) {
    return {
        path: pathWildcard,
        redirect: redirectPath

    }
}

const routes = [
    createPathRoute('/pt', 'pt-home', Home),
    createPathRoute('/pt/firm', 'pt-firm', Firm),
    createPathRoute('/pt/areas', 'pt-areas', Areas),
    createPathRoute('/pt/team', 'pt-team', Team),
    createPathRoute('/pt/news', 'pt-news', News),
    createPathRoute('/pt/news/infos', 'pt-news-infos', NewsInfos),
    createPathRoute('/pt/news/events', 'pt-news-events', NewsEvents),
    createPathRoute('/pt/contact', 'pt-contact', Contact),
    createPathRoute('/pt/terms-conditions', 'pt-terms-conditions', TermsConditions),
    createPathRoute('/pt/privacy-policy', 'pt-privacy-policy', PrivacyPolicy),
    createRedirectRoute('/pt/*', '/pt'),
    createPathRoute('/en', 'en-home', Home),
    createPathRoute('/en/firm', 'en-firm', Firm),
    createPathRoute('/en/areas', 'en-areas', Areas),
    createPathRoute('/en/team', 'en-team', Team),
    createPathRoute('/en/news', 'en-news', News),
    createPathRoute('/en/news/infos', 'en-news-infos', NewsInfos),
    createPathRoute('/en/news/events', 'en-news-events', NewsEvents),
    createPathRoute('/en/contact', 'en-contact', Contact),
    createPathRoute('/en/terms-conditions', 'en-terms-conditions', TermsConditions),
    createPathRoute('/en/privacy-policy', 'en-privacy-policy', PrivacyPolicy),
    createRedirectRoute('/en/*', '/en'),
    createRedirectRoute('/*', '/pt')
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    },
    routes
})

export default router
