<template>
    <div>
        <div class="row justify-content-center">
            <div class="card-container col-11 col-lg-4 d-flex flex-column">

                <!-- Address Information -->
                <p class="p-1 mb-auto d-none d-xl-block"></p>
                <table :summary="'Table with contacts for the office located at ' + office.address"
                       class="table table-borderless">
                    <tbody>
                    <tr>
                        <th class="text-center" scope="row"><em class="fas fa-mobile-alt fa-2x"></em></th>
                        <td>{{ office.cellPhone }}</td>
                    </tr>
                    <tr>
                        <th class="text-center" scope="row"><em class="fas fa-phone-square-alt fa-2x"></em></th>
                        <td>{{ office.telePhone }}</td>
                    </tr>
                    <tr>
                        <th class="text-center" scope="row"><em class="fas fa-fax fa-2x"></em></th>
                        <td>{{ office.fax }}</td>
                    </tr>
                    <tr class="email">
                        <th class="text-center" scope="row"><em class="fas fa-envelope-square fa-2x"></em></th>
                        <td><a :href="'mailto:' + office.email">{{ office.email }}</a></td>
                    </tr>
                    </tbody>
                </table>
                <p class="p-1 mb-auto d-none d-xl-block"></p>

                <!-- Modal Trigger element -->
                <button :data-target="'#'+office.id"
                        class="btn btn-oc-red btn-md btn-block py-2"
                        data-toggle="modal"
                        type="button">
                    {{ office.emailButtonText }}
                </button>
                <a :href="office.contactCard" class="py-2" download>
                    <button class="btn btn-oc-dark-grey btn-md btn-block"
                            type="button">{{ office.contactCardText }}
                    </button>
                </a>
                <p class="p-1 mb-auto d-none d-xl-block"></p>
            </div>
            <div class="card-container col-11 col-lg-5 px-0 mx-0">
                <div class="the-card">
                    <div :style="{'background-image': 'url(' + office.frontBGImage + ')'}"
                         class="the-front d-flex text-center align-items-center">
                        <div class="card-content">
                            <p>
                                <img :alt="''" :src="locationIcon" class="img-fluid">
                            </p>
                            <p v-html="office.address"></p>
                        </div>
                    </div>
                    <div :style="{'background-image': 'url(' + office.backBGImage + ')'}"
                         class="the-back d-flex text-center align-items-center">
                        <div class="card-content">
                            <p></p>
                            <a href="javascript:void(0)" @click.stop="backClick">
                                {{ office.mapText }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div :id="office.id" aria-hidden="true" aria-labelledby="exampleModalCenterTitle" class="modal fade"
             role="dialog" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ office.form.formName }}</h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form action="https://formspree.io/geral@osoriodecastroadvogados.pt"
                              method="POST"
                              novalidate="true"
                              @submit="mailSend">
                            <div v-if="errors.length">
                                <strong>{{ office.form.formErrorText }}</strong>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label for="name">{{ office.form.nameHeader }}</label>
                                <input id="name" v-model="form.name" :placeholder="office.form.namePlaceholder"
                                       class="form-control"
                                       name="name" type="text">
                            </div>
                            <div class="form-group">
                                <label for="name">{{ office.form.phoneContactHeader }}</label>
                                <input id="phone" v-model="form.phone"
                                       :placeholder="office.form.phoneContactPlaceholder"
                                       class="form-control"
                                       name="phone" type="text">
                            </div>
                            <div class="form-group">
                                <label for="name">{{ office.form.mailContactHeader }}</label>
                                <input id="email" v-model="form.email"
                                       :placeholder="office.form.mailContactPlaceholder" class="form-control"
                                       name="_replyto" type="email">
                            </div>
                            <div class="form-group">
                                <label for="subject">{{ office.form.subjectHeader }}</label>
                                <input id="subject" v-model="form.subject" class="form-control"
                                       name="subject" type="text">
                            </div>
                            <div class="form-group">
                                <label for="message">{{ office.form.messageHeader }}</label>
                                <textarea id="message" v-model="form.message" class="form-control"
                                          name="message"></textarea>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input v-model="form.privacy" class="form-check-input" type="checkbox"
                                           value="">{{ office.form.termsPrivacyText }}</label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input v-model="form.liability" class="form-check-input" type="checkbox"
                                           value="">{{ office.form.responsibilityText }}
                                </label>
                            </div>
                            <button class="btn btn-oc-red mt-1" type="submit">{{ office.form.buttonText }}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "OfficeContact",
    props: {
        office: {
            type: Object,
            default: () => {
                // Empty object
            },
        },
    },
    data() {
        return {
            errors: [],
            form: {
                name: '',
                phone: '',
                email: '',
                subject: '',
                message: '',
                privacy: '',
                liability: '',
            },
            locationIcon: '/assets/contact/LOCATION_ICON.png'
        }
    },
    methods: {
        backClick() {
            window.open(this.office.locationUrl, '_blank');
        },
        mailSend: function (e) {
            this.errors = [];
            if (!this.form.name) {
                this.errors.push(this.office.form.nameErrorText);
            }
            if (!this.form.phone) {
                this.errors.push(this.office.form.phoneContactErrorText);
            }
            if (!this.form.email) {
                this.errors.push(this.office.form.mailContactErrorText);
            }
            if (!this.form.subject) {
                this.errors.push(this.office.form.subjectErrorText);
            }
            if (!this.form.message) {
                this.errors.push(this.office.form.messageErrorText);
            }
            if (!this.form.privacy) {
                this.errors.push(this.office.form.termsPrivacyErrorText);
            }
            if (!this.form.liability) {
                this.errors.push(this.office.form.responsibilityErrorText);
            }

            if (!this.errors.length) {
                return true;
            }

            e.preventDefault();
        }
    },
}
</script>

<style scoped>


.card-container {
    position: relative;
    height: 400px;
}

.the-card {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 1.1s ease;
    font-weight: 500;
    font-size: 1.2em;
}

.card-container:hover > .the-card {
    transform: rotateY(180deg);
}

.the-front {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-size: 100% 100%;
    color: white;
}

.the-front img {
    width: 33%;
    height: 33%;
}

.the-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-size: 100% 100%;
    transform: rotateY(180deg);
}

.card-content {
    width: inherit;
}

.btn-oc-red {
    background-color: rgb(149, 37, 58);
    color: white;
}

.btn-oc-red:hover {
    opacity: 0.4;
}

.btn-oc-dark-grey {
    background-color: rgb(110, 110, 110);
    color: white;
}

.btn-oc-dark-grey:hover {
    opacity: 0.4;
}

.email a {
    color: black;
}
</style>
