// 1. Require the Storyblok client
import StoryblokClient from 'storyblok-js-client'

// 2. Set your token - you will be able to create a new space later.
const token = 'oFbsvHwKU4EZiqIwz4Y8Fwtt'; //production
//const token = 'w3SZa2LibMe4rQt2GmtMegtt'; //preview

// 3. Initialize the client with the preview token so we can access our API easily
// from your space dashboard at https://app.storyblok.com
const storyblokApi = new StoryblokClient({
    accessToken: token
})

export default storyblokApi;
