import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import {SET_PATH_LANG,} from "./mutation-types";

Vue.use(Vuex)

const state = {
    lang: window.location.pathname.split('/')[1]
};

const mutations = {
    [SET_PATH_LANG](state) {
        state.lang = window.location.pathname.split('/')[1];
    },
};

const actions = {
    async setPathLanguage({commit}) {
        commit(SET_PATH_LANG);
    },
};

const getters = {};

const modules = {};

const plugins = [createPersistedState()]

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules,
    plugins,
})
