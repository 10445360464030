<template>
    <div id="news-infos">
        <NavBar :selected-tab="'news-infos'"></NavBar>

        <div class="container pg-container">

            <router-link :to="{ name: buildRouteName(lang,'news')}">
                <div class="d-flex align-items-center news-return">
                    <em class="fas fa-arrow-alt-circle-left fa-3x"></em>
                    <span style="padding-left: 10px">{{ returnText }}</span>
                </div>
            </router-link>

            <div class="text-center pg_hd_txt">
                <h1>{{ header }}</h1>
            </div>

            <br>

            <div class="container">
                <div class="row justify-content-around">
                    <div v-for="(info, idx) in infos" class="col-md-6 col-xl-4 info">
                        <NewsInfoItem :id="''+idx" :info="info"></NewsInfoItem>
                    </div>
                </div>
            </div>

            <br>

        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import NewsInfoItem from "@/components/NewsInfoItem";
import storyblokApi from "@/api/storyblokApi";
import {mapActions, mapState} from "vuex";
import {CONTENT_VERSION} from '@/store/constants'
import langUtils from "@/helper/lang";
import storyblokMapper from "@/api/storyblokMapper";

const parentSlug = 'news';
const slug = 'infos';

export default {
    name: "NewsInfos",
    components: {
        NavBar,
        Footer,
        NewsInfoItem
    },
    async created() {
        await this.setPathLanguage();
        await this.initNewsInfosPage();
    },
    data() {
        return {
            infos: [],
            header: '',
            returnText: ''
        }
    },
    methods: {
        ...mapActions([
            'setPathLanguage'
        ]),
        async initNewsInfosPage() {
            storyblokApi
                .get('cdn/stories/' + this.lang + '/' + parentSlug + '/' + slug, {
                    version: CONTENT_VERSION,
                    is_startpage: 1
                })
                .then(res => {
                    const storyContent = res.data.story.content;

                    this.header = storyContent.Header;
                    this.returnText = storyContent.ReturnText;
                });

            storyblokApi
                .get('cdn/stories/', {
                    version: CONTENT_VERSION,
                    starts_with: this.lang + '/' + parentSlug + '/' + slug + '/',
                    is_startpage: 0
                })
                .then(res => {
                    this.infos = res.data.stories.map(infoStory => storyblokMapper.mapInfo(infoStory.content))
                });
        },
        buildRouteName(lang, link) {
            return langUtils.buildRouteName(lang, link);
        },
    },
    computed: {
        ...mapState({
            lang: 'lang',
        }),
    },
}
</script>

<style scoped>
a {
    color: rgb(149, 37, 58);
}

.news-return {
    padding: 10px 30px;
}

.info {
    padding: 10px 5px;
}
</style>
