<template>
    <div>
        <div class="phone d-none d-md-block">
            <em class="fas fa-mobile-alt"></em> +351 915 327 968 |
            <em class="fas fa-phone-square-alt"></em> +351 223 233 060 |
            <em class="fas fa-envelope-square"></em> <a
            href="mailto:geral@osoriodecastroadvogados.pt">geral@osoriodecastroadvogados.pt</a>
        </div>
        <div class="phone-reactive d-md-none">
            <p class="my-0 py-0"><em class="fas fa-mobile-alt"></em> +351 915 327 968</p>
            <p class="my-0 py-0"><em class="fas fa-phone-square-alt"></em> +351 223 233 060</p>
            <p class="my-0 py-0"><em class="fas fa-envelope-square"></em> <a
                href="mailto:geral@osoriodecastroadvogados.pt">geral@osoriodecastroadvogados.pt</a></p>
        </div>

        <nav id="oc-nav"
             class="navbar sticky-top navbar-expand-lg navbar-toggleable-lg navbar-light bg-faded">

            <router-link id="home-logo" :to="{ name: buildRouteName(lang,'home') }" class="navbar-brand">
                <img class="d-none d-md-block" alt="Osório de Castro e Associados logo" src="/assets/navbar/LOGO.png">
                <img class="d-md-none" style="max-width: 80%" alt="Osório de Castro e Associados logo" src="/assets/navbar/LOGO.png">
            </router-link>

            <button class="navbar-toggler"
                    data-target="#oc-navbar"
                    data-toggle="collapse"
                    type="button">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div id="oc-navbar"
                 class="navbar-collapse collapse">
                <ul class="navbar-nav ml-auto">
                    <li v-for="option in routingOptions"
                        class="nav-item">
                        <router-link :class="{active: selectedTab === option.link}"
                                     :to="{ name: buildRouteName(lang,option.link) }"
                                     class="nav-link">
                            {{ option.label }}
                        </router-link>
                    </li>
                </ul>
            </div>

            <button type="button" class="btn btn-secondary" v-on:click="changeLanguage">
                {{ toggleLang(lang).toUpperCase() }}
            </button>
        </nav>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import storyblokApi from "@/api/storyblokApi";
import {CONTENT_VERSION} from "@/store/constants";
import langUtils from "@/helper/lang";

const slug = 'header';

export default {
    name: "NavBar",
    props: {
        selectedTab: {
            type: String,
            default: () => '',
        }
    },
    data() {
        return {
            routingOptions: []
        }
    },
    async created() {
        await this.setPathLanguage();
        await this.initNavBar();
    },
    methods: {
        ...mapActions([
            'setPathLanguage'
        ]),
        async initNavBar() {
            storyblokApi
                .get('cdn/stories/' + this.lang + '/' + slug, {
                    version: CONTENT_VERSION
                })
                .then(res => {
                    const storyContent = res.data.story.content;
                    this.routingOptions = storyContent.HeaderItems.map(navItem => {
                        return {
                            link: navItem.Link,
                            label: navItem.Label
                        }
                    });
                });
        },
        toggleLang(lang) {
            return langUtils.toggleLang(lang);
        },
        buildRouteName(lang, link) {
            return langUtils.buildRouteName(lang, link);
        },
        changeLanguage() {
            this.$router.push({name: this.buildRouteName(this.toggleLang(this.lang), this.selectedTab)});
            window.location.reload();
        }
    },
    computed: {
        ...mapState({
            lang: 'lang',
        }),
    },
}
</script>

<style scoped>
.phone {
    background-color: rgb(149, 37, 58);
    color: white;
    text-align: right;
    padding: 0 24px;
    margin: 0 0;
}

.phone-reactive {
    background-color: rgb(149, 37, 58);
    color: white;
    text-align: center;
}

p {
    padding-left: 15px;
}

a {
    color: white;
}

#oc-nav {
    background-color: white;
    padding: 0 16px;
    width: 100%;
}

.nav-link {
    color: #6f6f6f;
    font-size: 0.9em;
    font-weight: 600;
    padding-bottom: 10px;
}

.navbar-nav a:hover {
    padding-bottom: 8px;
    border-bottom: 2px solid rgb(149, 37, 58);
}

.navbar-nav a.active {
    padding-bottom: 7px;
    border-bottom: 4px solid rgb(149, 37, 58);
}
</style>
