import storyblokApi from "@/api/storyblokApi";

function mapInfo(infoContent) {
    return {
        date: infoContent.Date,
        type: infoContent.Type,
        title: infoContent.Title,
        cardTitle: storyblokApi.richTextResolver.render(infoContent.CardTitle),
        content: storyblokApi.richTextResolver.render(infoContent.PreviewText),
        downloadText: infoContent.DownloadText,
        image: {
            path: infoContent.Image.filename,
            alt: infoContent.Image.alt
        },
        document: infoContent.Document.filename
    }
}

function mapEvent(eventContent) {
    return {
        date: eventContent.Date,
        hour: eventContent.Hour,
        type: eventContent.Type,
        title: eventContent.Title,
        content: storyblokApi.richTextResolver.render(eventContent.Content),
        image: {
            path: eventContent.Image.filename,
            alt: eventContent.Image.alt
        }
    }
}


const storyblokMapper = {
    mapInfo: mapInfo,
    mapEvent: mapEvent,
}

export default storyblokMapper;
