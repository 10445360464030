<template>
    <div>
        <div id="app">
            <router-view/>
        </div>

        <div class="d-none d-md-block">
            <a class="float-left d-flex justify-content-center align-items-center"
               href="https://api.whatsapp.com/send?phone=351915327968">
                <em class="fab fa-whatsapp fa-2x my-float"></em>
            </a>
        </div>

        <div class="d-md-none">
            <a class="float-right d-flex justify-content-center align-items-center"
               href="https://api.whatsapp.com/send?phone=351915327968">
                <em class="fab fa-whatsapp fa-2x my-float"></em>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "App"
}

$(document).ready(function () {
    var mouseX, mouseY, traX, traY;
    $(document).mousemove(function (e) {
        mouseX = e.pageX;
        mouseY = e.pageY;
        traX = ((4 * mouseX) / 100) + 40;
        traY = ((4 * mouseY) / 100) + 50;
        $(".pg_hd_txt").css({"background-position": traX + "%" + traY + "%"});
    });
});
</script>

<style>
html {
    font-family: Arial, serif;
}

.container {
    background-color: #fdfdfd;
}

.float-left {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 70px;
    left: 60px;
    background-color: #0C9;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: -3px 3px 3px #999;
}

.float-right {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 80px;
    right: 10px;
    background-color: #0C9;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: -3px 3px 3px #999;
}

.my-float {
    margin: 0 10px;
}

.cover-photo {
    width: 100%;
}

.cover-photo img {
    width: 100%;
}

.pg_hd_txt {
    font-weight: 900;
    color: transparent;
    background: url("/assets/navbar/firm-p.jpg") repeat;
    background-position: 40% 50%;
    -webkit-background-clip: text;
    text-align: center;
    letter-spacing: -1px;
}

.pg_hd_txt h1 {
    font-size: 3.5em;
}

.pg_hd_txt h6 {
    font-size: 1.3em;
}

.heavy {
    font-weight: bold;
}

/* OC COLORS */
.oc-light-grey {
    color: rgb(150, 150, 150);
}

.oc-dark-grey {
    color: rgb(110, 110, 110);
}

.oc-red {
    color: rgb(149, 37, 58);
}

.oc-white {
    color: white;
}
</style>
