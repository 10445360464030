<template>
    <div class="row">
        <div class="item">
            <div class="card-container">
                <div class="the-card">
                    <div class="the-front item-top d-flex text-center align-items-start">
                        <div class="card-content">
                            <div class="d-flex flex-column">
                                <h1>{{ event.type.toUpperCase() }}</h1>
                                <p><img :alt="''" :src="headerImage"></p>
                            </div>
                        </div>
                    </div>
                    <div class="the-back d-flex text-center align-items-center">
                        <div class="card-content">
                            <div v-if="event.image">
                                <img :alt="event.image.alt"
                                     :data-target="'#event'+id"
                                     :src="event.image.path"
                                     class="img-fluid"
                                     data-toggle="modal">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-bottom d-flex flex-column justify-content-around">
                <div class="text-center align-self-center">
                    <h5><strong>{{ event.title }}</strong></h5>
                    <p class="date-time">{{ event.date }} | {{ event.hour }}</p>
                </div>
                <div>
                    <span :style="styleObject(event.content)" v-html="event.content"></span>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div :id="'event'+id"
             aria-hidden="true"
             aria-labelledby="exampleModalCenterTitle"
             class="modal fade"
             role="dialog"
             tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ event.title }}</h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <!-- ROW with image and CV -->
                        <img :alt="event.image.alt"
                             :src="event.image.path"
                             class="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NewsEventItem",
    props: {
        event: {
            type: Object,
            default: () => {
                // Empty object
            },
        },
        id: {
            type: String,
            default: () => null,
        },
    },
    data() {
        return {
            headerImage: '/assets/navbar/LOGO.png'
        }
    },
    methods: {
        styleObject(text) {
            return {
                fontSize: text.length > 450 ? ((21 - Math.ceil(text.length / 90)) + 'px') : '17px',
            }
        }
    },
}
</script>

<style scoped>
.row {
    width: 100%;
    padding: 2px 2px;
    margin: 0;
}

.d-flex {
    width: 100%;
}

.item {
    width: 100%;
}

.item-top {
    border-style: solid none solid none;
    border-color: rgb(145, 132, 133);
    border-width: 15px;
    background-color: white;
}

.item-top h1 {
    background-color: rgb(216, 208, 192);
    margin: 0;
    padding: 12px;
    font-size: 2.5em;
    font-weight: normal;
}

.item-top img {
    height: 100% !important;
    width: 62% !important;
    padding: 10px;
}

.item-bottom {
    height: 450px;
    background-color: rgb(233, 230, 231);
    padding: 5px;
}

.date-time {
    font-weight: bold;
    color: rgb(149, 37, 58);
}

p {
    margin: 0
}

span {
    text-align: justify;
}

.the-card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 1.1s ease;
    font-weight: 500;
    font-size: 1.2em;
}

.item:hover > .card-container > .the-card {
    transform: rotateY(180deg);
}

.the-front {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-size: 100% 100%;
    color: white;
}

.the-front img {
    width: 33%;
    height: 33%;
}

.the-back {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-size: 100% 100%;
    transform: rotateY(180deg);
}

.card-content {
    width: inherit;
}
</style>
