<template>
    <div id="terms">
        <NavBar :selected-tab="'terms-conditions'"></NavBar>

        <div class="container pg-container">

            <br>

            <div class="text-center pg_hd_txt">
                <h1>{{ header }}</h1>
            </div>

            <br>

            <div v-for="(term, index) in terms" class="container" data-spy="scroll">
                <h2 class="oc-red">{{ index + 1 }}. {{ term.title }}</h2>
                <div v-html="term.text"></div>
            </div>

            <br>

        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import {mapActions, mapState} from "vuex";
import storyblokApi from "@/api/storyblokApi";
import {CONTENT_VERSION} from "@/store/constants";

const slug = 'terms-conditions';

export default {
    name: "TermsConditions",
    components: {
        Footer,
        NavBar
    },
    async created() {
        await this.setPathLanguage();
        await this.initTermsConditionsPage();
    },
    data() {
        return {
            header: '',
            terms: []
        }
    },
    methods: {
        ...mapActions([
            'setPathLanguage'
        ]),
        async initTermsConditionsPage() {
            storyblokApi
                .get('cdn/stories/' + this.lang + '/' + slug, {
                    version: CONTENT_VERSION,
                    is_startpage: 1
                })
                .then(res => {
                    const storyContent = res.data.story.content;

                    this.header = storyContent.Header;
                    this.terms = storyContent.Sections.map(term => {
                        return {
                            title: term.Title,
                            text: storyblokApi.richTextResolver.render(term.Text)
                        }
                    });
                });
        },
    },
    computed: {
        ...mapState({
            lang: 'lang',
        }),
    },
}
</script>

<style scoped>
p {
    text-align: justify
}
</style>
