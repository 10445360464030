<template>
    <div id="contact">
        <NavBar :selected-tab="'contact'"></NavBar>
        <div class="cover-photo">
            <img :alt="bannerImage.alt" :src="bannerImage.path" class="d-none d-md-block">
            <figure>
                <div class="d-md-none cover-photo-mobile" :style='bannerImageStyle'></div>
            </figure>
        </div>

        <div class="container pg-container">

            <br>

            <div class="text-center pg_hd_txt">
                <h1>{{ header }}</h1>
                <h6>{{ subHeader }}</h6>
            </div>

            <br>

            <div v-for="office in offices" class="container">
                <OfficeContact :office="office"></OfficeContact>
                <br>
            </div>

            <Newsletter></Newsletter>

        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import OfficeContact from "@/components/OfficeContact";
import Newsletter from "@/components/Newsletter";
import {mapActions, mapState} from "vuex";
import storyblokApi from "@/api/storyblokApi";
import {CONTENT_VERSION} from "@/store/constants";

const slug = 'contacts';

export default {
    name: "Contact",
    components: {
        Newsletter,
        Footer,
        NavBar,
        OfficeContact
    },
    async created() {
        await this.setPathLanguage();
        await this.initContactPage();
    },
    data() {
        return {
            bannerImage: {},
            header: '',
            subHeader: '',
            offices: []
        }
    },
    methods: {
        ...mapActions([
            'setPathLanguage'
        ]),
        async initContactPage() {
            storyblokApi
                .get('cdn/stories/' + this.lang + '/' + slug, {
                    version: CONTENT_VERSION,
                    is_startpage: 1
                })
                .then(res => {
                    const storyContent = res.data.story.content;

                    this.bannerImage = {
                        path: storyContent.BannerImage.filename,
                        alt: storyContent.BannerImage.alt,
                    }

                    this.header = storyContent.Header;
                    this.subHeader = storyContent.SubHeader;

                    this.offices = storyContent.Sections.map(section => {
                        const contactForm = section.ContactForm[0]

                        return {
                            id: section.Id,
                            cellPhone: section.CellPhone,
                            telePhone: section.TelePhone,
                            fax: section.Fax,
                            email: section.ElectronicAddress,
                            emailButtonText: section.EmailButtonText,
                            contactCard: section.ContactCard.filename,
                            contactCardText: section.ContactCardText,
                            address: storyblokApi.richTextResolver.render(section.Address),
                            mapText: section.MapText,
                            frontBGImage: section.FrontOfficeImage.filename,
                            backBGImage: section.MapImage.filename,
                            locationUrl: section.LocationUrl,

                            form: {
                                formName: contactForm.FormName,
                                formErrorText: contactForm.FormErrorText,
                                nameHeader: contactForm.NameHeader,
                                namePlaceholder: contactForm.NamePlaceholder,
                                nameErrorText: contactForm.NameErrorText,
                                phoneContactHeader: contactForm.PhoneContactHeader,
                                phoneContactPlaceholder: contactForm.PhoneContactPlaceholder,
                                phoneContactErrorText: contactForm.PhoneContactErrorText,
                                mailContactHeader: contactForm.MailContactHeader,
                                mailContactPlaceholder: contactForm.MailContactPlaceholder,
                                mailContactErrorText: contactForm.MailContactErrorText,
                                subjectHeader: contactForm.SubjectHeader,
                                subjectErrorText: contactForm.SubjectErrorText,
                                messageHeader: contactForm.MessageHeader,
                                messageErrorText: contactForm.MessageErrorText,
                                termsPrivacyText: contactForm.TermsPrivacyText,
                                termsPrivacyErrorText: contactForm.TermsPrivacyErrorText,
                                responsibilityText: contactForm.ResponsibilityText,
                                responsibilityErrorText: contactForm.ResponsibilityErrorText,
                                buttonText: contactForm.ButtonText
                            }
                        }
                    });
                });
        },
    },
    computed: {
        ...mapState({
            lang: 'lang',
        }),
        bannerImageStyle: function () {
            return {backgroundImage: "url(" + this.bannerImage.path + ")",}
        },
    },
}
</script>

<style scoped>
figure {
    margin: 0;
}

.cover-photo-mobile {
    height: 180px;
    background-position: 0 center;
    background-repeat: no-repeat;
    background-size: cover;
}

</style>
