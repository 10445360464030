<template>
    <div class="footer-div">
        <div class="container">
            <div class="row justify-content-center">
                <p class="col-12 text-center">© <em>Osório de Castro & Associados, Sociedade de Advogados, SP, RL</em>
                </p>

                <div v-for="(option,idx) in routingOptions" class="text-center pages">
                    <router-link :to="{ name: buildRouteName(lang, option.link)}"
                                 class="nav-link d-inline">
                        {{ option.label }}
                    </router-link>
                    <span v-if="(idx + 1) < routingOptions.length"> | </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import storyblokApi from "@/api/storyblokApi";
import {CONTENT_VERSION} from "@/store/constants";
import langUtils from "@/helper/lang";

const slug = 'footer';

export default {
    name: "Footer",
    data() {
        return {
            routingOptions: []
        }
    },
    async created() {
        await this.setPathLanguage();
        await this.initFooter();
    },
    methods: {
        ...mapActions([
            'setPathLanguage'
        ]),
        async initFooter() {
            storyblokApi
                .get('cdn/stories/' + this.lang + '/' + slug, {
                    version: CONTENT_VERSION
                })
                .then(res => {
                    const storyContent = res.data.story.content;
                    this.routingOptions = storyContent.FooterItems.map(navItem => {
                        return {
                            link: navItem.Link,
                            label: navItem.Label
                        }
                    });
                });
        },
        buildRouteName(lang, link) {
            return langUtils.buildRouteName(lang, link);
        },
    },
    computed: {
        ...mapState({
            lang: 'lang',
        }),
    },
}
</script>

<style scoped>
.footer-div {
    background-color: black;
    color: #fdfdfd;
    padding: 10px 0;
}

.footer-div .row {
    padding: 0 20px;
    width: 100%;
}

.container {
    background-color: black;
}

.pages {
    font-size: .75em;
}

p {
    margin-bottom: 0;
    font-size: .75em;
}

a {
    color: white;
}
</style>
