<template>
    <div>
        <!-- Begin Mailchimp Signup Form -->
        <!--
        <div id="mc_embed_signup">
            <form
                id="mc-embedded-subscribe-form"
                action="https://osoriodecastroadvogados.us2.list-manage.com/subscribe/post?u=826046b3e66a15d73e0a7b243&amp;id=07e95a86c5"
                class="validate" method="post" name="mc-embedded-subscribe-form"
                novalidate target="_blank">
                <div id="mc_embed_signup_scroll">
                    <h2>Subscreva à nossa Newsletter</h2>
                    <div class="indicates-required"><span class="asterisk">*</span> Campos Obrigatórios</div>
                    <div class="form-group">
                        <input id="mce-EMAIL" class="form-control" name="EMAIL" type="email" value=""
                               placeholder="Correio Eletrónico *">
                    </div>
                    <div class="form-group">
                        <input id="mce-FNAME" class="form-control" name="FNAME" type="text" value=""
                               placeholder="Primeiro Nome">
                    </div>
                    <div class="form-group">
                        <input id="mce-LNAME" class="form-control" name="LNAME" type="text" value=""
                               placeholder="Último Nome">
                    </div>
                    <div id="mce-responses" class="clear">
                        <div id="mce-error-response" class="response" style="display:none"></div>
                        <div id="mce-success-response" class="response" style="display:none"></div>
                    </div>
                    <div aria-hidden="true" style="position: absolute; left: -5000px;">
                        <input
                            name="b_826046b3e66a15d73e0a7b243_07e95a86c5"
                            tabindex="-1"
                            type="text" value="">
                    </div>
                    <div class="clear"><input id="mc-embedded-subscribe" class="button" name="subscribe"
                                              type="submit" value="Subscrever"></div>
                </div>
            </form>
        </div>
        -->
        <!--End Mailchimp Signup Form -->
    </div>

</template>

<script>
export default {
    name: "Newsletter",
    methods: {}
}
</script>

<style scoped>
#mc_embed_signup_scroll {
    width: 50%;
}
</style>

