function buildRouteName(lang, link) {
    return lang + '-' + link;
}

function toggleLang(lang) {
    return lang === 'pt' ? 'en' : 'pt';
}

export default {
    buildRouteName: buildRouteName,
    toggleLang: toggleLang
};
