<template>
    <div id="terms">
        <NavBar :selected-tab="'privacy-policy'"></NavBar>

        <div class="container pg-container">

            <br>

            <div class="text-center pg_hd_txt">
                <h1>{{ header }}</h1>
            </div>

            <br>

            <div v-for="(policy, index) in policies" class="container" data-spy="scroll">
                <h2 class="oc-red">{{ index + 1 }}. {{ policy.title }}</h2>
                <div v-html="policy.text"></div>
            </div>

            <br>

        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import {mapActions, mapState} from "vuex";
import storyblokApi from "@/api/storyblokApi";
import {CONTENT_VERSION} from "@/store/constants";

const slug = 'privacy-policy';

export default {
    name: "PrivacyPolicy",
    components: {
        Footer,
        NavBar
    },
    async created() {
        await this.setPathLanguage();
        await this.initPrivacyPolicyPage();
    },
    data() {
        return {
            header: '',
            policies: []
        }
    },
    methods: {
        ...mapActions([
            'setPathLanguage'
        ]),
        async initPrivacyPolicyPage() {
            storyblokApi
                .get('cdn/stories/' + this.lang + '/' + slug, {
                    version: CONTENT_VERSION,
                    is_startpage: 1
                })
                .then(res => {
                    const storyContent = res.data.story.content;

                    this.header = storyContent.Header;
                    this.policies = storyContent.Sections.map(policy => {
                        return {
                            title: policy.Title,
                            text: storyblokApi.richTextResolver.render(policy.Text)
                        }
                    });
                });
        },
    },
    computed: {
        ...mapState({
            lang: 'lang',
        }),
    },
}
</script>

<style scoped>
p {
    text-align: justify
}
</style>
