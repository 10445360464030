<template>
    <div id="news">
        <NavBar :selected-tab="'news'"></NavBar>
        <div class="cover-photo">
            <img :alt="bannerImage.alt" :src="bannerImage.path" class="d-none d-md-block">
            <figure>
                <div class="d-md-none cover-photo-mobile" :style='bannerImageStyle'></div>
            </figure>
        </div>

        <div class="container pg-container">

            <br>

            <div class="text-center pg_hd_txt">
                <h1>{{ header }}</h1>
                <h6>{{ subHeader }}</h6>
            </div>

            <br>

            <div class="container">

                <div v-for="section in newsSections" class="my-3">
                    <div class="row">
                        <div class="col-2 text-center">
                            <img :alt="''" :src="section.sectionIcon" class="img-fluid">
                        </div>
                        <div class="col-5 d-flex align-items-center">
                            <h4 class="oc-red">{{ section.sectionTitle }}</h4>
                        </div>
                        <div class="col-5 d-flex align-items-center justify-content-end text-center">
                            <router-link :to="{ name: buildRouteName(lang,buildRouteName('news',section.newsType))}">
                                <h6>{{ section.accessText }}</h6>
                            </router-link>
                        </div>
                    </div>
                    <div v-if="section.newsType == 'events'">
                        <div class="row justify-content-around">
                            <div v-for="(event, idx) in events" class="col-md-6 col-xl-4 event">
                                <NewsEventItem :id="''+idx" :event="event"></NewsEventItem>
                            </div>
                        </div>
                    </div>
                    <div v-if="section.newsType == 'infos'">
                        <div class="row justify-content-around">
                            <div v-for="(info, idx) in infos" class="col-md-6 col-xl-4 info">
                                <NewsInfoItem :id="''+idx" :info="info"></NewsInfoItem>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br>

        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import NewsInfoItem from "@/components/NewsInfoItem";
import NewsEventItem from "@/components/NewsEventItem";
import {mapActions, mapState} from "vuex";
import storyblokApi from "@/api/storyblokApi";
import {CONTENT_VERSION} from "@/store/constants";
import langUtils from "@/helper/lang";
import storyblokMapper from "@/api/storyblokMapper";

const slug = 'news';
const infosSlug = 'infos';
const eventsSlug = 'events';

export default {
    name: "News",
    components: {
        NavBar,
        NewsInfoItem,
        NewsEventItem,
        Footer,
    },
    async created() {
        await this.setPathLanguage();
        await this.initNewsPage();
    },
    data() {
        return {
            bannerImage: {},
            header: '',
            subHeader: '',
            newsSections: [],
            infos: [],
            events: []
        }
    },
    methods: {
        ...mapActions([
            'setPathLanguage'
        ]),
        async initNewsPage() {
            storyblokApi
                .get('cdn/stories/' + this.lang + '/' + slug, {
                    version: CONTENT_VERSION,
                    is_startpage: 1
                })
                .then(res => {
                    const storyContent = res.data.story.content;

                    this.bannerImage = {
                        path: storyContent.BannerImage.filename,
                        alt: storyContent.BannerImage.alt,
                    }

                    this.header = storyContent.Header;
                    this.subHeader = storyContent.SubHeader;

                    this.newsSections = storyContent.Sections.map(section => {
                        return {
                            newsType: section.NewsType,
                            sectionTitle: section.SectionTitle,
                            sectionIcon: section.SectionIcon.filename,
                            accessText: section.AccessText
                        }
                    })
                });

            storyblokApi
                .get('cdn/stories/', {
                    version: CONTENT_VERSION,
                    starts_with: this.lang + '/' + slug + '/' + infosSlug + '/',
                    is_startpage: 0,
                    page: 1,
                    per_page: 3
                })
                .then(res => {
                    this.infos = res.data.stories.map(infoStory => storyblokMapper.mapInfo(infoStory.content))
                });

            storyblokApi
                .get('cdn/stories/', {
                    version: CONTENT_VERSION,
                    starts_with: this.lang + '/' + slug + '/' + eventsSlug + '/',
                    is_startpage: 0,
                    page: 1,
                    per_page: 3
                })
                .then(res => {
                    this.events = res.data.stories.map(eventStory => storyblokMapper.mapEvent(eventStory.content))
                });
        },
        buildRouteName(lang, link) {
            return langUtils.buildRouteName(lang, link);
        },
    },
    computed: {
        ...mapState({
            lang: 'lang',
        }),
        bannerImageStyle: function () {
            return {backgroundImage: "url(" + this.bannerImage.path + ")",}
        },
    },
}
</script>

<style scoped>
figure {
    margin: 0;
}

.cover-photo-mobile {
    height: 175px;
    background-position: 50% center;
    background-repeat: no-repeat;
    background-size: cover;
}

.info {
    padding: 10px 5px;
}

.event {
    padding: 10px 5px;
}

a {
    color: rgb(149, 37, 58);
}
</style>
