<template>
    <div>
        <!-- Modal Trigger element -->
        <div class="container text-center d-none d-md-block">
            <img :alt="member.portraitImage.alt"
                 :data-target="'#'+member.id"
                 :src="member.portraitImage.path"
                 class="img-fluid portrait-img"
                 data-toggle="modal"/>
            <h5 class="text-center pt-3">{{ member.name }}</h5>
        </div>
        <div class="container text-center d-md-none">
            <img :alt="member.portraitImage.alt"
                 :data-target="'#'+member.id"
                 :src="member.portraitImage.path"
                 class="img-fluid portrait-img-mobile"
                 data-toggle="modal"/>
            <h5 class="text-center pt-3">{{ member.name }}</h5>
        </div>

        <!-- Modal -->
        <div :id="member.id"
             aria-hidden="true"
             aria-labelledby="exampleModalCenterTitle"
             class="modal fade"
             role="dialog"
             tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-xl"
                 role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ member.name }}</h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <!-- ROW with image and CV -->
                        <div class="row align-items-center"
                             style="padding-bottom: 10px; margin-bottom: 10px;">
                            <div class="col-md-4">
                                <img :alt="member.fullImage.alt" :src="member.fullImage.path" class="img-fluid"/>
                            </div>
                            <div class="col-md-8 team-points">
                                <p v-html="member.points"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TeamMember",
    props: {
        member: {
            type: Object,
            default: () => {
                // Empty object
            },
        },
    },
    methods: {}
}
</script>

<style scoped>
.portrait-img {
    height: 300px;
    filter: grayscale(50%);
    transition: transform .2s; /* Animation */
}

.portrait-img-mobile {
    height: 200px;
    filter: grayscale(50%);
    transition: transform .2s; /* Animation */
}

.portrait-img:hover {
    filter: grayscale(0%);
    transform: scale(1.1);
}

.team-points {
    text-align: justify;
    overflow-y: auto;
    max-height: 550px;
}
</style>
