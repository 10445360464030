<template>
    <div id="firm">
        <NavBar :selected-tab="'firm'"></NavBar>
        <div class="cover-photo">
            <img :alt="bannerImage.alt" :src="bannerImage.path" class="d-none d-md-block">
            <figure>
                <div class="d-md-none cover-photo-mobile" :style='bannerImageStyle'></div>
            </figure>
        </div>

        <div class="container pg-container">

            <br>

            <div class="text-center pg_hd_txt">
                <h1>{{ header }}</h1>
                <h6>{{ subHeader }}</h6>
            </div>

            <br>

            <div class="container">
                <div v-for="section in firmSections">
                    <h2 class="oc-red">{{ section.title }}</h2>
                    <p v-html="section.body"></p>
                </div>
            </div>

            <br>

        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import storyblokApi from "../api/storyblokApi"
import {mapActions, mapState} from "vuex";
import {CONTENT_VERSION} from '@/store/constants'

const slug = 'firm';

export default {
    name: "Firm",
    components: {
        Footer,
        NavBar
    },
    data() {
        return {
            bannerImage: {},
            header: '',
            subHeader: '',
            firmSections: []
        }
    },
    async created() {
        await this.setPathLanguage();
        await this.initFirmPage();
    },
    methods: {
        ...mapActions([
            'setPathLanguage'
        ]),
        async initFirmPage() {
            storyblokApi
                .get('cdn/stories/' + this.lang + '/' + slug, {
                    version: CONTENT_VERSION
                })
                .then(res => {
                    const storyContent = res.data.story.content;

                    this.bannerImage = {
                        path: storyContent.BannerImage.filename,
                        alt: storyContent.BannerImage.alt,
                    }

                    this.header = storyContent.Header;
                    this.subHeader = storyContent.SubHeader;

                    this.firmSections = storyContent.Sections.map(section => {
                        return {
                            title: section.SectionTitle,
                            body: storyblokApi.richTextResolver.render(section.SectionBody)
                        }
                    });
                });
        },
    },
    computed: {
        ...mapState({
            lang: 'lang',
        }),
        bannerImageStyle: function () {
            return {backgroundImage: "url(" + this.bannerImage.path + ")",}
        }
    },
}
</script>

<style scoped>
p {
    text-align: justify;
}

figure {
    margin: 0;
}

.cover-photo-mobile {
    height: 190px;
    background-position: 0 center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
