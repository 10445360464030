<template>
    <div class="row">
        <div class="item">
            <div class="card-container">
                <div class="the-card">
                    <div class="the-front item-top d-flex text-center align-items-start">
                        <div class="card-content">
                            <div class="d-flex flex-column">
                                <h1>{{ info.type.toUpperCase() }}</h1>
                                <p><img :alt="''" :src="headerImage"></p>
                            </div>
                        </div>
                    </div>
                    <div class="the-back d-flex text-center align-items-center">
                        <div class="card-content">
                            <!-- Image Modal Trigger element -->
                            <div v-if="info.image">
                                <img :alt="info.image.alt"
                                     :data-target="'#info'+id"
                                     :src="info.image.path"
                                     class="img-fluid d-none d-md-block"
                                     data-toggle="modal">
                                <img :alt="info.image.alt"
                                     :src="info.image.path"
                                     class="img-fluid d-md-none">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-bottom d-flex flex-column justify-content-between">
                <div class="text-center align-self-center">
                    <h5 v-html="info.cardTitle"></h5>
                    <p class="date-time">{{ info.date }}</p>
                </div>
                <div>
                    <span :style="styleObject(info.content)" v-html="info.content"></span>
                </div>
                <!-- Document Modal Trigger element -->
                <div class="doc-container d-flex flex-column justify-content-center align-items-center">
                    <p v-if="info.downloadText">{{ info.downloadText }}:</p>
                    <img :alt="''"
                         :data-target="'#info-doc'+id"
                         :src="pdfImage"
                         class="img-fluid document-icon col-6 d-none d-md-block"
                         data-toggle="modal"/>
                    <a :href="info.document" class="col-6 d-md-none" download>
                        <img :alt="''"
                             :src="pdfImage"
                             class="img-fluid document-icon"/>
                    </a>
                </div>
            </div>
        </div>

        <!-- Image Modal -->
        <div :id="'info'+id"
             aria-hidden="true"
             aria-labelledby="exampleModalCenterTitle"
             class="modal fade"
             role="dialog"
             tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ info.title }}</h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <img :alt="info.image.alt"
                             :src="info.image.path"
                             class="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>

        <!-- Document Modal -->
        <div :id="'info-doc'+id"
             aria-hidden="true"
             aria-labelledby="exampleModalCenterTitle"
             class="modal fade"
             role="dialog"
             tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-xl"
                 role="document">
                <div class="modal-content document-modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ info.title }}</h5>
                        <button aria-label="Close" class="close" data-dismiss="modal"
                                type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <embed :src="info.document" height="100%" type="application/pdf"
                               width="100%">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "NewsInfoItem",
    props: {
        info: {
            type: Object,
            default: () => {
                // Empty object
            },
        },
        id: {
            type: String,
            default: () => null,
        },
    },
    data() {
        return {
            headerImage: '/assets/navbar/LOGO.png',
            imageSrc: '/assets/news/logos/info.png',
            pdfImage: '/assets/news/logos/PDF.png'
        }
    },
    methods: {
        styleObject(text) {
            return {
                fontSize: text.length > 450 ? ((21 - Math.ceil(text.length / 90)) + 'px') : '17px',
            }
        }
    },
}
</script>

<style scoped>

.document-modal-content {
    height: 775px;
}

.doc-container {
    background-color: rgb(233, 230, 231);
}

.row {
    width: 100%;
    padding: 2px 2px;
    margin: 0;
}

.d-flex {
    width: 100%;
}

.item {
    width: 100%;
}

.item-top {
    border-style: solid none solid none;
    border-color: rgb(145, 132, 133);
    border-width: 10px;
    background-color: white;
}

.item-top h1 {
    background-color: rgb(216, 208, 192);
    margin: 0;
    padding: 20px;
    font-size: 2em;
    font-weight: normal;
}

.item-top img {
    height: 100% !important;
    width: 62% !important;
    padding: 10px;
}

.item-bottom {
    height: 500px;
    background-color: rgb(233, 230, 231);
    padding: 5px;
}

.date-time {
    font-weight: bold;
    color: rgb(149, 37, 58);
}

p {
    margin: 0
}

span {
    text-align: justify;
    font-size: 0.95em;
}

.the-card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 1.1s ease;
    font-weight: 500;
    font-size: 1.2em;
}

.item:hover > .card-container > .the-card {
    transform: rotateY(180deg);
}

.the-front {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-size: 100% 100%;
    color: white;
}

.the-front img {
    width: 33%;
    height: 33%;
}

.the-back {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-size: 100% 100%;
    transform: rotateY(180deg);
}

.card-content {
    width: inherit;
}
</style>

