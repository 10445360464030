<template>
    <div id="areas">
        <NavBar :selected-tab="'areas'"></NavBar>
        <div class="cover-photo">
            <img :alt="bannerImage.alt" :src="bannerImage.path" class="d-none d-md-block">
            <figure>
                <div class="d-md-none cover-photo-mobile" :style='bannerImageStyle'></div>
            </figure>
        </div>

        <div class="container pg-container">

            <br>

            <div class="text-center pg_hd_txt">
                <h1>{{ header }}</h1>
                <h6>{{ subHeader }}</h6>
            </div>

            <br>

            <div class="container d-none d-md-block">
                <ul class="container areas-list">
                    <li v-for="(area,idx) in areas" class="area-item">
                        <div v-if="idx % 2 === 0" class="row justify-content-start align-items-center">
                            <div class="col-3 text-center">
                                <img :alt="area.image.alt" :src="area.image.path" class="img-fluid area-image"/>
                            </div>
                            <div class="col-9">
                                <h3 class="oc-red">{{ area.name }}</h3>
                                <span class="desc" v-html="area.desc"></span>
                            </div>
                        </div>
                        <div v-if="idx % 2 === 1" class="row align-items-center">
                            <div class="col-9 test-right">
                                <h3 class="oc-red">{{ area.name }}</h3>
                                <span class="desc" v-html="area.desc"></span>
                            </div>
                            <div class="col-3 text-center">
                                <img :alt="area.image.alt" :src="area.image.path" class="img-fluid area-image"/>
                            </div>
                        </div>
                        <hr v-if="idx !== areas.length - 1" class="bookends">
                    </li>
                </ul>
            </div>

            <div class="container d-md-none">
                <ul class="container areas-list">
                    <li v-for="(area,idx) in areas" class="area-item">
                        <div class="row justify-content-start">
                            <div :data-target="'#'+area.id" class="col-12 text-center" data-toggle="collapse">
                                <img :alt="area.image.alt" :src="area.image.path" class="img-fluid area-image-mobile"/>
                                <h3 class="oc-red">{{ area.name }}</h3>
                            </div>

                            <div :id="area.id" class="col-12 collapse">
                                <span class="desc" v-html="area.desc"></span>
                            </div>
                        </div>
                        <hr v-if="idx !== areas.length - 1" class="bookends">
                    </li>
                </ul>
            </div>

            <br>

        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import {mapActions, mapState} from "vuex";
import storyblokApi from "@/api/storyblokApi";
import {CONTENT_VERSION} from "@/store/constants";

const slug = 'areas';

export default {
    name: "Areas",
    components: {
        Footer,
        NavBar
    },
    data() {
        return {
            bannerImage: {},
            header: '',
            subHeader: '',
            areas: []
        }
    },
    async created() {
        await this.setPathLanguage();
        await this.initAreasPage();
    },
    methods: {
        ...mapActions([
            'setPathLanguage'
        ]),
        async initAreasPage() {
            storyblokApi
                .get('cdn/stories/' + this.lang + '/' + slug, {
                    version: CONTENT_VERSION
                })
                .then(res => {
                    const storyContent = res.data.story.content;

                    this.bannerImage = {
                        path: storyContent.BannerImage.filename,
                        alt: storyContent.BannerImage.alt,
                    }

                    this.header = storyContent.Header;
                    this.subHeader = storyContent.SubHeader;

                    this.areas = storyContent.Sections.map(section => {
                        return {
                            id: section.Id,
                            name: section.SectionTitle,
                            image: {
                                path: section.SectionIcon.filename,
                                alt: section.SectionIcon.alt
                            },
                            desc: storyblokApi.richTextResolver.render(section.SectionDescription)
                        }
                    })
                });
        }
    },
    computed: {
        ...mapState({
            lang: 'lang',
        }),
        bannerImageStyle: function () {
            return {backgroundImage: "url(" + this.bannerImage.path + ")",}
        },
    },
}
</script>

<style scoped>
figure {
    margin: 0;
}

.cover-photo-mobile {
    height: 180px;
    background-position: 60% center;
    background-repeat: no-repeat;
    background-size: cover;
}

.areas-list {
    list-style-type: none;
}

.test-right {
    text-align: right;
}

.desc {
    text-align: justify
}

hr {
    border: 0;
    margin: 1.2em auto;
    max-width: 100%;
    background-position: 50%;
    box-sizing: border-box;
}

.bookends {
    position: relative;
    border-width: 5px;
    border-color: hsl(0, 0%, 50%) transparent;
    height: 11px;
    border-style: double;
    width: 70%;
}

.bookends:before,
.bookends:after {
    position: absolute;
    bottom: -3.536px;
    width: 7px;
    height: 7px;
    display: block;
    border-width: 0 7px 7px 0;
    border-color: hsl(0, 0%, 50%);
    border-style: double;
    box-sizing: border-box;
}

.bookends:before {
    transform: translateZ(0) rotate(-45deg);
    left: -20px;
}

.bookends:after {
    transform: translateZ(0) rotate(135deg);
    right: -20px;
}

li {
    padding: 15px 0 0 0;
}

.area-image {
    width: 90%;
}

.area-image-mobile {
    width: 60%;
}
</style>
