<template>
    <div id="home">
        <NavBar :selected-tab="'home'"></NavBar>
        <div class="cover-photo">
            <img :alt="bannerImage.alt" :src="bannerImage.path" class="d-none d-md-block">
            <figure>
                <div class="d-md-none cover-photo-mobile" :style='bannerImageStyle'></div>
            </figure>
        </div>

        <div class="container pg-container">
            <br>
            <br>

            <div class="d-none d-md-block">
                <div class="row justify-content-center align-items-center">
                    <div v-for="value in homeValues" :class="value.classObj" :style="value.styleObj">
                        <img :alt="value.image.alt" :src="value.image.path" class="img-fluid my-20">
                    </div>
                </div>
                <div class="row justify-content-center align-items-center">
                    <div v-for="value in homeValues" :class="value.classObj" :style="value.styleObj">
                        <div v-if="!!value.text" class="desc">{{ value.text }}</div>
                    </div>
                </div>
            </div>

            <div class="d-md-none">
                <div class="row justify-content-center align-items-center">
                    <div v-for="value in homeValues" :class="value.classObj" :data-target="'#'+value.name"
                         :style="value.styleObj" data-toggle="collapse">
                        <img :alt="value.image.alt" :src="value.image.path" class="img-fluid my-20">
                        <div :id="value.name" class="collapse">
                            <div v-if="!!value.text" class="desc">{{ value.text }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <br>
            <br>

            <div v-for="call in callsToAction">
                <router-link :to="{ name: buildRouteName(lang,call.link)}">
                    <img :alt="call.image.alt" :src="call.image.path" class="img-fluid">
                </router-link>
            </div>

        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import {mapActions, mapState} from "vuex";
import storyblokApi from "@/api/storyblokApi";
import {CONTENT_VERSION} from "@/store/constants";
import langUtils from "@/helper/lang";

export default {
    name: "Home",
    components: {
        Footer,
        NavBar
    },
    data() {
        return {
            bannerImage: {},
            homeValues: [],
            callsToAction: []
        }
    },
    async created() {
        await this.setPathLanguage();
        await this.initHome();
    },
    methods: {
        ...mapActions([
            'setPathLanguage'
        ]),
        async initHome() {
            storyblokApi
                .get('cdn/stories/' + this.lang + '/', {
                    version: CONTENT_VERSION
                })
                .then(res => {
                    const storyContent = res.data.story.content;

                    this.bannerImage = {
                        path: storyContent.BannerImage.filename,
                        alt: storyContent.BannerImage.alt,
                    }

                    this.homeValues = storyContent.Sections
                        .filter(section => section.component === 'Home Value')
                        .map(value => {
                            return {
                                name: value.Name,
                                classObj: value.ClassObject,
                                styleObj: value.StyleObject,
                                image: {
                                    path: value.ValueImage.filename,
                                    alt: value.ValueImage.alt
                                },
                                text: value.Text
                            }
                        });

                    this.callsToAction = storyContent.Sections
                        .filter(section => section.component === 'Call To Action')
                        .map(call => {
                            return {
                                link: call.Link,
                                image: {
                                    path: call.Image.filename,
                                    alt: call.Image.alt
                                }
                            }
                        });
                });
        },
        buildRouteName(lang, link) {
            return langUtils.buildRouteName(lang, link);
        },
    },
    computed: {
        ...mapState({
            lang: 'lang',
        }),
        bannerImageStyle: function () {
            return {backgroundImage: "url(" + this.bannerImage.path + ")",}
        }
    },
}
</script>

<style scoped>
.pg-container {
    padding: 0;
}

figure {
    margin: 0;
}

.cover-photo-mobile {
    height: 250px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.desc {
    text-align: center;
}

.row {
    margin-left: 0;
    margin-right: 0;
}
</style>
