<template>
    <div id="team">
        <NavBar :selected-tab="'team'"></NavBar>
        <div class="cover-photo">
            <img :alt="bannerImage.alt" :src="bannerImage.path" class="d-none d-md-block">
            <figure>
                <div class="d-md-none cover-photo-mobile" :style='bannerImageStyle'></div>
            </figure>
        </div>

        <div class="container pg-container">

            <br>

            <div class="text-center pg_hd_txt">
                <h1>{{ header }}</h1>
                <h6>{{ subHeader }}</h6>
            </div>

            <br>

            <div v-for="teamSection in teamSections" class="container">

                <h3 class="pg-sub-sec-title oc-red">{{ teamSection.sectionTitle }}</h3>

                <div class="row pg-sub-section justify-content-around">
                    <div v-for="member in teamSection.sectionMembers" class="col-lg-5 team-member">
                        <TeamMember :member="member"></TeamMember>
                    </div>
                </div>

            </div>

            <br>

        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import TeamMember from "@/components/TeamMember";
import storyblokApi from "@/api/storyblokApi";
import {mapActions, mapState} from "vuex";
import {CONTENT_VERSION} from '@/store/constants'

const slug = 'team';

export default {
    name: "Team",
    components: {
        Footer,
        NavBar,
        TeamMember
    },
    async created() {
        await this.setPathLanguage();
        await this.initTeamPage();
    },
    data() {
        return {
            bannerImage: {},
            header: '',
            subHeader: '',
            teamSections: []
        }
    },
    methods: {
        ...mapActions([
            'setPathLanguage'
        ]),
        async initTeamPage() {
            storyblokApi
                .get('cdn/stories/' + this.lang + '/' + slug, {
                    version: CONTENT_VERSION
                })
                .then(res => {
                    const storyContent = res.data.story.content;

                    this.bannerImage = {
                        path: storyContent.BannerImage.filename,
                        alt: storyContent.BannerImage.alt,
                    }

                    this.header = storyContent.Header;
                    this.subHeader = storyContent.SubHeader;

                    this.teamSections = storyContent.Sections.map(section => {
                        return {
                            sectionTitle: section.SectionTitle,
                            sectionMembers: section.SectionMembers.map(member => {
                                return {
                                    id: member.Identifier,
                                    name: member.Name,
                                    portraitImage: {
                                        path: member.PortraitImage.filename,
                                        alt: member.PortraitImage.alt,
                                    },
                                    fullImage: {
                                        path: member.FullImage.filename,
                                        alt: member.FullImage.alt,
                                    },
                                    points: storyblokApi.richTextResolver.render(member.Remarks)
                                }
                            })
                        }
                    })
                });
        }
    },
    computed: {
        ...mapState({
            lang: 'lang',
        }),
        bannerImageStyle: function () {
            return {backgroundImage: "url(" + this.bannerImage.path + ")",}
        },
    },
}
</script>

<style scoped>
figure {
    margin: 0;
}

.cover-photo-mobile {
    height: 190px;
    background-position: 60% center;
    background-repeat: no-repeat;
    background-size: cover;
}

.team-member {
    margin: 15px 0;
}
</style>
